import React, { Component } from 'react';

const API_ADDRESS = 'https://spotify-api-wrapper.appspot.com';

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            updateArtist : props.updateArtist, 
            updateTracks : props.updateTracks,
            getArtistId  : props.getArtistId,
            artistQuery : '',
        }
    }
    
    topTracksQueryUrl = () => 
    (`${API_ADDRESS}/artist/${this.state.getArtistId()}/top-tracks`);

    artistQueryUrl = () => 
        (`${API_ADDRESS}/artist/${this.state.artistQuery}`);

    updateQuery = event => {
        this.setState({artistQuery: event.target.value});
    }

    handleKeyPress = event => {
        //console.log("in key press",event);
        if (event.key === 'Enter') {
            this.searchArtist();
        }
    }

    searchArtist = () => {
        let query = this.artistQueryUrl();

        fetch(query.trim())
        .then((response) => response.json())
        .then(json => {
                //this.setState({artistJson: json});
                if (json.artists.total > 0) {
                    let artist = json.artists.items[0];
                    this.state.updateArtist(artist);
                    //this.setState({artist})
                    this.getArtistTopTracks();
                } else {
                    this.state.updateArtist(null);
                    this.state.updateTracks(null);
                    //this.setState({artist: null});
                    //this.setState({tracks: null});
                }
            })
        .catch(error => alert(error.message));
    };


    getArtistTopTracks = () => {
        let query = this.topTracksQueryUrl();
        fetch(query)
        .then((response) => response.json())
        .then(json => {
                //this.setState({tracksJson: json});
                if (json.tracks && (json.tracks.length > 0)) {
                    //this.setState({tracks: json.tracks});
                    this.state.updateTracks(json.tracks);
                } else {
                    //this.setState({tracks: null});
                    this.state.updateTracks(null);
                }
                //this.setState({tracksJson: json.tracks});
                
            })
        .catch(error => alert(error.message));
    };


    render() {

        return (
            <div>
                <div>
                    <h2>Music Search</h2>
                    <input onChange={this.updateQuery}  onKeyPress={this.handleKeyPress}
                    placeholder='Search for an Artist'/>
                    <button>Search</button>
                </div>
                <h2 onClick={this.searchArtist}>Search Results</h2>

        </div>
        );
    }
}

export default Search;
