import React, { Component } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

class Tracks extends Component {
    state = { playing: false, audio: null, playingPreviewUrl: null };

    playAudio = previewUrl => () => {

        if (this.state.playing && this.state.audio) {
            this.state.audio.pause();
        }

        if (!previewUrl) {
            this.setState( prevState => ({ playing: false, audio : null, 
                playingPreviewUrl : null}));
            //alert('No preview available');
            return;
        }

        const playNewSong = 
                   (previewUrl !== this.state.playingPreviewUrl)
                           || !this.state.playing;

        if (playNewSong) {
            const audio = new Audio(previewUrl);
            audio.play();
            this.setState( prevState => ({ playing: true, audio : audio, 
                playingPreviewUrl : previewUrl}));
        } else {
            this.setState( prevState => ({ playing: false, audio : null, 
                playingPreviewUrl : null}));
        }
    }
    
    trackIcon = track => {


        if (!track.preview_url) {
            return (
                <OverlayTrigger placement='top'
                    overlay={
                    <Tooltip id='tooltip-top'>
                    <strong>No preview available</strong>.
                    </Tooltip>
                    }>
                    <span>N/A</span>
                </OverlayTrigger>
            );
        }

        if (this.state.playing 
            && this.state.playingPreviewUrl === track.preview_url) {
                return <OverlayTrigger placement='top'
                    overlay={
                    <Tooltip id='tooltip-top'>
                    <strong>Stop preview</strong>.
                    </Tooltip>
                    }>
                    <span className="fa fa-stop"></span>
                </OverlayTrigger>;
        } else {
                return <OverlayTrigger placement='top'
                    overlay={
                    <Tooltip id='tooltip-top'>
                    <strong>Play preview</strong>.
                    </Tooltip>
                    }>
                    <span className="fa fa-play"></span>
                </OverlayTrigger>;
        }

    }
    render() {
        const {tracks} = this.props;
        if (!tracks) return (<div></div>);

        return  (   
            <div>
                {tracks.map((track) => {
                    const {id, name, album, preview_url } = track;

                    return (
                        <div 
                            key={id} 
                            className='track'
                            onClick={this.playAudio(preview_url)}
                        >

                            <img 
                                src={album.images[0] && album.images[0].url} 
                                alt={name}
                                className='track-image'
                            />
                        <p className='track-text'>{name}</p>
                        <p className='track-icon'>{this.trackIcon(track)}</p>
                    </div>
                    );
                 }
            )}
           </div> 
        );
    }
}


export default Tracks;

/*
<div className='mm-tooltip'>N/A
  <span className='mm-tooltiptext'>No Preview Available</span>
</div>
                                        

<div className="tooltip" className="bs-tooltip-top" role="tooltip">
  <div className="arrow">Hello</div>
  <div className="tooltip-inner">
    Some tooltip text!
  </div>
</div>



                           <figcaption style={{width:200}}>
                           {name}</figcaption>


                           <div class="tooltip">Hover over me
  <span class="tooltiptext">Tooltip text</span>
</div>

            return <span>&#9654;</span>;

return <span>| |</span>;
*/
/*
        if (!tracks[0]) return null;
        console.log("Tracks tracks",tracks);
            </div>

            <div className="topTracks">
            <h1>Hello Jack</h1>
            <p>Name: {tracks[0].name} ID: {tracks[0].id}</p>

                Name: {tracks[0].name}
                {tracks.map((track) => {
                    //const {id, name, album} = track;
                    //console.log("mapping id:",id,"name",name,"album",album,"track",track);
                    <figure key={track.id}>
                        <a href="">
                            <img src={track.album.images[0] 
                            && track.album.images[0].url} 
                            alt={track.name}/>
                            <figcaption>
                                {track.name}
                            </figcaption>
                        </a>
                        </figure>})}

{tracks.map((track) => <p key={track.id}>{track.name} {track.id}</p>)}                        


        if (this.state.playing 
            && (previewUrl !== this.state.playingPreviewUrl)) {
            let audio = this.state.audio 
            if (audio) {
                audio.pause();
            }
            playNewSong = true;
        }


        if (playNewSong || !this.state.playing) {
            let audio = new Audio(previewUrl);
            audio.play();
            this.setState( prevState => ({ playing: true, audio : audio, 
                playingPreviewUrl : previewUrl}));
        } else {
            let audio = this.state.audio 
            if (audio) {
                audio.pause();
            }
            this.setState(prevState => ({ playing: false, playingPreviewUrl: null}));
        }


*/
/*
                <span>N/A
                    <div className='tooltip'>
                        Hello There
                        <span className='tooltiptext'>No Preview Available</span>
                   </div>
                </span>

*/