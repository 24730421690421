import React, { Component } from 'react';
import AboutMe from './AboutMe';

class Home extends Component {
    render() {
        return (
        <div>
            <div className="d-flex justify-content-center">
                <h2>Welcome to Richard Logwood's Website :)</h2>
            </div>
            <AboutMe/>
        </div>
        );
    }
}

export default Home;


