import email_icon from '../assets/images/email_icon.png'
import linkedin_icon from '../assets/images/linkedin_icon.png'
import github_icon from '../assets/images/github_icon.png'
import twitter_icon from '../assets/images/twitter_icon.png'
import { Button } from 'react-bootstrap';

const SOCIAL_NETWORKS = [{
        id: 1,
        name: 'Email',
        icon: email_icon,
        link: 'mailto:richard.logwood@gmail.com'
    },
    {
        id: 2,
        name: 'Github',
        icon: github_icon,
        link: 'https://github.com/rlogwood'
    },
    {
        id: 3,
        name: 'LinkedIn',
        icon: linkedin_icon,
        link: 'https://www.linkedin.com/in/richard-logwood/'
    },
    {
        id: 4,
        name: 'Twitter',
        icon: twitter_icon,
        link: 'https://twitter.com/RichardLogwood'
    }

];

export default SOCIAL_NETWORKS;
