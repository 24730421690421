import React, { Component } from 'react';
import SOCIAL_NETWORKS from '../data/socialNetworks';
import ContactByEmailPopUp from './ContactByEmailPopUp';

class SocialNetwork extends Component {


    render() {
        const {name, icon, link} = this.props.socialNetwork;
        if (name === "Email") {
            return (
                <ContactByEmailPopUp email_icon={icon} email_text={name}/>
            );

        } else {
            return(
                <span style={{margin:10}}>
                <a href={link}><img src={icon} alt={name} style={{width:35}}/></a>
                </span>
            );
        }
    }
}

const SocialNetworks = () => (
    <div>
        <div className="d-flex justify-content-center">
            <h3>Connect with me!</h3>
        </div>
        <div className="d-flex justify-content-center align-items-center">
        {
            SOCIAL_NETWORKS.map( SOCIAL_NETWORK => (
                <SocialNetwork key={SOCIAL_NETWORK.id} socialNetwork={SOCIAL_NETWORK}/>
            ))
        }
        </div>
    </div>
);

export default SocialNetworks;


                // <span style={{margin:10}}>
                // <a href={link}><img src={icon} alt={name} style={{width:35}}/></a>
                // </span>
