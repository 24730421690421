import './MusicMaster.css';
import React, { Component } from 'react'
import Artist from './Artist';
import Tracks from './Tracks';
import Search from './Search';


//import ApiDebugInfo from './ApiDebugInfo';
//import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
//import Tooltip from 'react-bootstrap/Tooltip'

class MusicMaster extends Component {
    state = { 
        artist: null,
        tracks: []
        };

    updateArtist = (artist) => {
        this.setState({artist : artist});
    }

    updateTracks = (tracks) => {
        this.setState({tracks : tracks});
    }

    getArtistId = () => {
        return this.state.artist ?  this.state.artist.id : '';
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-center">
                    <Search updateArtist={this.updateArtist}
                        updateTracks={this.updateTracks}
                        getArtistId={this.getArtistId} />
                </div>
                <div className="d-flex justify-content-center">
                    <Artist artist={this.state.artist}/>
                </div>
                <div className="d-flex justify-content-center">
                    <Tracks tracks={this.state.tracks}/>
                </div>
            </div>
        );
    }
}

export default MusicMaster;