import React, { Component } from 'react';
import Resume from './Resume';

class About extends Component {
    render() {
        return (
            <div>
                <div className="d-flex justify-content-center">
                    <h2>More about me</h2>
                </div>
                <Resume/>
            </div>
        );
    }
}

export default About;
    