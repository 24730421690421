import React, { Component } from 'react';
import axios from 'axios';

const csrfToken = document.querySelector('[name="csrf-token"]').content;
console.log("Found CSRF Token:(",csrfToken,")");
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

class Contact extends Component {
    handleSubmit(e) {
        //debugger
        event.preventDefault();
        let message = e.target.elements[0].value;
        //alert('calling axios.post');
//        console.log('login clicked')
        let data = JSON.stringify({
            message: message
        })
    
        // axios.post('api/contact', data, {
        //         headers: {
        //             'Content-Type': 'application/json',
        //         }
        //     }
        // )
        //   .then(function (response) {
        //     console.log(response);
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //   });

        //api/contact_me
//post '/api/contact_me', to: 'users#contact_me'
        axios.post('/api/contact_me_via_email', {
            message: `${message}`
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });

//        axios.post('/contact', `message=${message}`)
//        .then( (data) => {
//            alert(data);});
            //debugger});
    }

    render() {
        return (
            <div className="container" style={{padding:'40px 0'}}>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <h1>Contact Us</h1>
                        <form onSubmit={this.handleSubmit.bind(this)}>
                            <input type="text" name="message" className="form-control" placeholder="Send us a message!"/>
                            <button className="btn btn-primary" type="submit">Contact Us</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
