import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//import { Navbar, Nav, NavItem, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { Route, RouteHandler} from 'react-router';
//import AuthService from '../services/AuthService'

import { Button, Nav, Navbar, NavDropdown, MenuItem, NavItem, Container } from 'react-bootstrap';

import { LinkContainer } from 'react-router-bootstrap';
import ContactByEmailPopUp from './ContactByEmailPopUp';
import email_icon from '../assets/images/email_icon.png'
import Title from './Title';

//import profile from '../../assets/images/profile.jpg'



import logs1 from '../assets/images/logs1_300x200.jpg';

class Navigation extends Component {
       
  constructor(props) {
    super(props);
    this.state = { navExpanded: false };
  }


  setNavExpanded = (expanded) => {
    this.setState(prevState => ({ navExpanded: expanded }));
    //this.setState(prevState => { navExpanded: expanded });
  }

  toggleNav(){
    return !this.state.navExpanded
    //this.setState(prevState => { navExpanded: prevState.navExpanded });
  }

  openNav(){
    this.setState({ navExpanded: true });
  }


  closeNav = () => {
    this.setState(prevState => ({ navExpanded: false }));
  }
  
  // ...
  //<Nav pullRight>
  //  <NavItem componentClass={Link} href="/" to="/">Home</NavItem>
  //  <NavItem componentClass={Link} href="/book" to="/book">Book Inv</NavItem>
  //</Nav>


    render() {
      //data-toggle="collapse" data-target="#navbar-collapse-1"
      //onToggle={this.openNav}
        //onToggle={this.toggle} 
        //expanded={this.state.expanded} 
        //onSelect={this.complexOnSelect}
//collapseOnSelect="false" 
      return (
              <Navbar collapseOnSelect expand="sm"  fixed="top"
                    className="bg-light justify-content-between">
                  <Container>
                    <LinkContainer to="/">
                     <Navbar.Brand>
                     <img src={logs1} alt='Richard Logwood' className='profile-header'/>
                     </Navbar.Brand>
                    </LinkContainer>

                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <LinkContainer to="/">
                          <Nav.Link>Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/about">
                          <Nav.Link>About</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/music" activeClassName='selectedMenuItem'>
                          <Nav.Link>Music</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/bootstrap_react_test" activeClassName='selectedMenuItem'>
                          <Nav.Link>Test</Nav.Link>
                        </LinkContainer>
                        <Navbar.Text>
                          <Title/>
                        </Navbar.Text>

                    </Nav>
                  </Navbar.Collapse>
                  </Container>
              </Navbar>
        );

    }


}


export default Navigation;
/*
        <LinkContainer to="/contact">
          <Nav.Item>
              <ContactByEmailPopUp  email_icon={email_icon} email_text="Email Me"/>
          </Nav.Item>          
        </LinkContainer>

*/