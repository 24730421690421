import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

import './custom.scss';
import '../assets/stylesheets/index.css';
import './media_queries.scss';
import App from '../components/App'
import Navigation from '../components/Navigation';
import SocialNetworks from '../components/SocialNetworks';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Container>
      <Router>
        <div>
          <Navigation/>
          <div style={{marginTop:"70px"}}>
            <Route path="/" component={App} />
            <SocialNetworks/>
          </div>
       </div>
      </Router>
    </Container>,
    document.body.appendChild(document.createElement('div'))
  )
})
