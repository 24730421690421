import React, { Component } from 'react'

const TITLES = [
    'a software engineer',
    'a music lover',
    'a Tigger fan, meow!',
    'a guitar player',
    'evolving this website',
    'a futurist',
    'living in the present',
    'a Kotlin afficinado',
    'a Ruby and Rails lover',
    'a lover not a fighter',
    'a longboarder',
    'a .NET Core fan',
    'an Azure Portal admirer',
    'a problem solver',
    'a people person',
    'a wanna be robot builder',
    'a disc thrower',
    'booted up by Spring',
    'sorry Amazon isn\'t coming to NYC'
];

class Title extends Component {
    state = { titleIndex: 0, fadeIn: true};

    componentDidMount() {
        this.fadeTimeout = setTimeout(() => {this.setState({fadeIn: false});}, 2000);
        this.animateTitles(); 
    }

    componentWillUnmount() {
        clearInterval(this.titleInterval);
        clearTimeout(this.fadeTimeout);
    }

    animateTitles = () => {
        this.titleInterval = setInterval(()=> {
            const titleIndex = (this.state.titleIndex + 1) % TITLES.length;
            this.setState({ titleIndex });
            this.setState({fadeIn: true});
            this.fadeTimeout = setTimeout(() => {this.setState({fadeIn:false})}, 2000);
        }, 4000);
    }

    render() {
        const { fadeIn, titleIndex } = this.state;

        const title = TITLES[titleIndex];
        return (
            <h3 style={{paddingLeft:"20px"}} className={fadeIn ? 'title-fade-in' : 'title-fade-out'}>I am {title}</h3>
        )
    }
}

export default Title;
