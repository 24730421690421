import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
//const resume_html = 

class Resume extends Component {


    render() { 
        return (
            <div className="resume">
<Table border="0" cellSpacing="0" cellPadding="0" width="720">
    <tbody>
        <tr>
            <td width="720" valign="top">
                <p>
                    <strong>Summary</strong>
                    : Seeking opportunity to build something wonderful :)
                </p>
                <p>
                    My experience in building large complex software solutions
                    for demanding clients enhances my ability to contribute.
                </p>
                <p>
                    I have a passion for making a difference, effective
                    collaboration and building bulletproof software. I enjoy
                    leveraging Agile and DevOps best practices to improve
                    velocity and reliability of releases.
                </p>
                <p>
                    Finding the best (and extensible) practical solution for
                    difficult problems motivates me. Working to improve the
                    process along the way makes the work fun.
                </p>
                <p>
                    <em>
                        Skills: Technology Immersed, Passionate about getting
                        to the best possible solution, People person, Ruby on
                        Rails, Java, C#, ASP.NET Core, Kotlin, React JS, SQL,
                        Python, Perl, Web Frameworks, JavaScript, DevOps, Fixed
                        Income, Results Driven, Self-Motivated and
                        Collaborative, Creative and Practical, Great
                        Communicator.
                    </em>
                </p>
                <p>
                    <em>
                        Interests: Big Data, AI, Reactive Programming,
                        Community Involvement, Ultimate
                    </em>
                </p>
                <p>
                    <em></em>
                </p>
                <Table border="0" cellSpacing="0" cellPadding="0" width="720">
                    <tbody>
                        <tr>
                            <td width="568" valign="top">
                                <p>
                                    <strong>
                                        Adaptive Financial Consulting –
                                        Software Engineer – New York, NY
                                    </strong>
                                </p>
                            </td>
                            <td width="152" valign="top">
                                <p align="right">
                                    <strong> 01/2018 – 05/2018</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="720" colSpan="2" valign="top">
                                <p>
                                    <em>
                                        Full Stack Developer C# / Java / Kotlin
                                        / React JS
                                    </em>
                                </p>
                                <ul>
                                    <li>
                                        Implemented cross platform
                                        authentication for high speed
                                        communication infrastructure (Java /C#)
                                    </li>
                                    <li>
                                        Tested OAuth providers and made
                                        recommendations for client project
                                        (Auth0, Okta, Azure AD,Amazon Cognito,
                                        JavaScript / HTML / APIs tested via
                                        Postman)
                                    </li>
                                    <li>
                                        Created distributed ledger
                                        implementation for management of
                                        trading confirmations (Corda /Java /
                                        Kotlin / Spring Boot / React JS /
                                        Bootstrap)
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </td>
        </tr>
    </tbody>
</Table>
<Table border="0" cellSpacing="0" cellPadding="0" width="720">
    <tbody>
        <tr>
            <td width="568" valign="top">
                <p>
                    <strong>
                        Lord Abbett – Consultant Product Data - Jersey City, NJ
                    </strong>
                </p>
            </td>
            <td width="152" valign="top">
                <p align="right">
                    <strong> 08/2012 – 12/2018</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="720" colSpan="2" valign="top">
                <p>
                    <em>Java Product Data Developer.</em>
                </p>
                <ul>
                    <li>
                        Created month end exhibit reporting pipeline for fund
                        and benchmark characteristics (examples include average
                        YTM, duration, coupon) and breakdowns (examples include
                        asset class, credit quality, maturity)
                    </li>
                    <li>
                        System sources data from accounting systems, index
                        providers and external data providers (Yield Book,
                        Markit, Bloomberg, rating agencies) to create database
                        with fund and index holdings and their indicative and
                        analytic attributes.
                    </li>
                    <li>
                        Multiple sources for the same attribute are prioritized
                        according to business methodology
                    </li>
                    <li>
                        Holdings are tagged with an internal unique security
                        identifier and firm asset categorization
                    </li>
                    <li>
                        Architected and developed flexible exhibit generation
                        process that is extensible and XML message driven and
                        produces over 7,000 exhibits monthly.
                    </li>
                    <li>
                        Setup Continuous Integration Processes in TeamCity to
                        build, test and regress code changes on check-in
                    </li>
                    <li>
                        Developed an Excel regression tool to allow comparison
                        of exhibit data from different sources (for example,
                        production and development) on the same or different
                        as-of-dates.
                    </li>
                </ul>
                <p>
                    <em>
                        Java/Perl/Python/MS SQL/Windows/TeamCity CI/Excel
                        VBA/Perforce/Jira Based Agile Process (Kanban Board
                        Style)
                    </em>
                </p>
            </td>
        </tr>
    </tbody>
</Table>
<Table border="0" cellSpacing="0" cellPadding="0" width="712">
    <tbody>
        <tr>
            <td width="576" valign="top">
                <p>
                    <strong>Startup – Principal - New York, NY</strong>
                </p>
            </td>
            <td width="136" valign="top">
                <p align="right">
                    <strong> 12/2009 – 06/2012</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="712" colSpan="2" valign="top">
                <ul>
                    <li>
                        Created concept, designed and implemented software for
                        a social networking and marketing service. Service
                        designed to bring together product, services and people
                        based on reputation.
                    </li>
                    <li>
                        Project employed an Agile TDD process and was deployed
                        in a scalable cloud infrastructure utilizing a NOSQL
                        Database.
                    </li>
                </ul>
                <p>
                    <em>
                        Google-App Engine/Datastore/Full Text
                        Search/Python/HTML/OAuth/JavaScript/jQuery/Windows
                    </em>
                </p>
            </td>
        </tr>
    </tbody>
</Table>
<Table border="0" cellSpacing="0" cellPadding="0" width="714">
    <tbody>
        <tr>
            <td width="576" valign="top">
                <p>
                    <strong>
                        Citadel Group – Consultant Fixed Income - New York, NY
                    </strong>
                </p>
            </td>
            <td width="138" valign="top">
                <p align="right">
                    <strong>06/2008 – 11/2009</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="714" colSpan="2" valign="top">
                <p>
                    <em>C# Fixed Income Developer.</em>
                </p>
                <ul>
                    <li>
                        Designed and implemented fixed income risk management
                        desktop application. System leveraged firm’s existing
                        SOA architecture via TIBCO ESB for analytics and data.
                    </li>
                    <li>
                        Designed and developed Whole Loan analysis application
                        used by traders to evaluate portfolios for purchase.
                    </li>
                </ul>
                <p>
                    o 3-Tier architecture utilized WCF to connect data and
                    analytic sources.
                </p>
                <p>
                    o System allowed traders to visualize portfolios payment
                    history, defaults and geographic concentrations.
                </p>
                <ul>
                    <li>
                        Authored new P&amp;L spreadsheets for rates desk using
                        new C++/Python analytics library. Reconciled results
                        with trader authored VBA sheets slated for retirement.
                    </li>
                </ul>
                <p>
                    <em>C#/WPF/WCF/Excel/Python/VBA/Sybase/MSSQL/Windows</em>
                </p>
            </td>
        </tr>
    </tbody>
</Table>
<br clear="all"/>
<Table border="0" cellSpacing="0" cellPadding="0" width="720">
    <tbody>
        <tr>
            <td width="576" valign="top">
                <p>
                    <strong>IT Tooler – Consultant - New York, NY</strong>
                </p>
            </td>
            <td width="138" colSpan="2" valign="top">
                <p align="right">
                    <strong>04/2006 – 05/2008</strong>
                </p>
            </td>
            <td width="6">
            </td>
        </tr>
        <tr>
            <td width="714" colSpan="3" valign="top">
                <p>
                    <em>C# Developer.</em>
                </p>
                <ul>
                    <li>
                        Destination Management Company – Designed and
                        implemented system to automate their workflow pipeline
                        from a series of discrete steps using Microsoft Word,
                        Outlook and Excel into a multi-user software program
                        that allows them to book new business and manage
                        existing travel programs. - C#/WPF/WCF/MSSQL
                    </li>
                    <li>
                        Developed Management tool for ISVs. - C#/WPF
                    </li>
                    <li>
                        Developed Visual Studio add-in for easier configuration
                        management. - C#/Window Forms
                    </li>
                </ul>
                <p>
                    <em>C#/WPF/WCF/ADO.NET/ASP.NET/XML/MSSQL/Windows</em>
                </p>
            </td>
            <td width="6">
            </td>
        </tr>
        <tr>
            <td width="584" colSpan="2" valign="top">
                <p>
                    <strong>
                        Credit Suisse – Consultant Credit Derivatives - New
                        York, NY
                    </strong>
                </p>
            </td>
            <td width="136" colSpan="2" valign="top">
                <p align="right">
                    <strong>07/2003 – 03/2006</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="720" colSpan="4" valign="top">
                <p>
                    <em>C#/C++ Fixed Income Developer.</em>
                </p>
                <ul>
                    <li>
                        Integrated SunGard’s Panorama trading and risk system
                        into existing modeling and data framework at CS.
                    </li>
                    <li>
                        Used COM pluggable architecture to add firm specific
                        assets, pricing model, trade valuation and PCA.
                    </li>
                    <li>
                        Created P&amp;L decomposition reports, market-data
                        retrieval component for valuation models, user- input
                        screens.
                    </li>
                    <li>
                        Used existing trader spreadsheets to reconcile results.
                    </li>
                    <li>
                        Implemented London/New York source code control
                        framework to effectively manage global team
                        collaboration and releases.
                    </li>
                </ul>
                <p>
                    <em>C#/C++/COM/VB/Perl/Oracle/Windows</em>
                </p>
            </td>
        </tr>
        <tr height="0">
            <td width="432">
            </td>
            <td width="6">
            </td>
            <td width="98">
            </td>
            <td width="5">
            </td>
        </tr>
    </tbody>
</Table>
<Table border="0" cellSpacing="0" cellPadding="0" width="720">
    <tbody>
        <tr>
            <td width="576" valign="top">
                <p>
                    <strong>
                        Merrill Lynch – VP Fixed Income Development Manager -
                        New York, NY
                    </strong>
                </p>
            </td>
            <td width="144" valign="top">
                <p align="right">
                    <strong>09/1995 – 12/2001</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="720" colSpan="2" valign="top">
                <p>
                    <em>“Hands-on” C++ Fixed Income Development Manager.</em>
                </p>
                <ul>
                    <li>
                        Headed five-member infrastructure and production
                        support team for global fixed income analysis
                        application.
                    </li>
                </ul>
                <p>
                    o Re-engineered overnight processing pipeline for increased
                    reliability and faster completion.
                </p>
                <p>
                    o 3-Tier application – core components shared by all
                    applications; analytic cubes for MBS
                </p>
                <p>
                    o OAS, data engine cluster. UI presented via
                    Motif/X-Windows.
                </p>
                <p>
                    o Web based status console.
                </p>
                <p>
                    o Supported mortgage trading and sales.
                </p>
                <p>
                    o Responsible for infrastructure design and implementation.
                </p>
                <ul>
                    <li>
                        Headed four-member team building bond analytic
                        libraries.
                    </li>
                </ul>
                <p>
                    o Merged closed form and cash flow based bond libraries.
                </p>
                <p>
                    o Analytics libraries used throughout firm.
                </p>
                <p>
                    o Directly supported futures traders.
                </p>
                <p>
                    <em>C++/Perl/TCL/HTML/Latex/Sybase/SQL/Windows/Unix</em>
                </p>
            </td>
        </tr>
    </tbody>
</Table>
<Table border="0" cellSpacing="0" cellPadding="0" width="720">
    <tbody>
        <tr>
            <td width="576" valign="top">
                <p>
                    <strong>
                        Prudential Securities – Dir. Portfolio Group
                        Development Manager - New York, NY
                    </strong>
                </p>
            </td>
            <td width="144" valign="top">
                <p align="right">
                    <strong>06/1989 –12/1994</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="720" colSpan="2" valign="top">
                <p>
                    <em>“Hands-on” Fixed Income Development Manager.</em>
                </p>
                <ul>
                    <li>
                        Headed five-member team providing fixed income
                        portfolio and risk analytics for Sales and Trading.
                    </li>
                </ul>
                <p>
                    o Designed and built 3-tier fixed income portfolio analysis
                    system.
                </p>
                <p>
                    o System produced scenario based cash flow analytics for
                    MBS, Agency and Corporate bonds.
                </p>
                <p>
                    o Data tier provided access to issued securities, custom
                    securities, portfolio definitions and scenarios, portfolio
                    holdings and portfolio analytics.
                </p>
                <p>
                    o Users performed parallel yield curve shifts and horizon
                    total return analysis specifying price or yield.
                </p>
                <p>
                    o High performance computing farm used for OAS analysis.
                </p>
                <p>
                    <em>C/Oracle/VMS/Unix</em>
                </p>
            </td>
        </tr>
    </tbody>
</Table>
<Table border="0" cellSpacing="0" cellPadding="0" width="720">
    <tbody>
        <tr>
            <td width="584" valign="top">
                <p>
                    <strong>
                        L.F. Rothschild &amp; Co – Developer - New York, NY
                    </strong>
                </p>
            </td>
            <td width="136" valign="top">
                <p align="right">
                    <strong>08/1987 – 05/1989</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td width="720" colSpan="2" valign="top">
                <p>
                    <em>C Fixed Income Developer.</em>
                </p>
                <ul>
                    <li>
                        Developed analytic libraries to support the issuance,
                        trading and sale of MBS.
                    </li>
                    <li>
                        Maintained Agency prepayment databases.
                    </li>
                    <li>
                        Designed and implemented historical prepayment reports
                        and “tape cracking” system.
                    </li>
                </ul>
                <p>
                    <em>C/Unix</em>
                </p>
            </td>
        </tr>
    </tbody>
</Table>
<p align="center">
    Education: Bachelor’s Degree in Computer Science, Virginia Tech,
    Blacksburg, VA
</p>
      </div>);
    }

}


export default Resume;