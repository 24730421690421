//import React from 'react';
import React, { Component } from 'react';
import { ButtonToolbar, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import email_icon from '../assets/images/email_icon.png';

const csrfToken = document.querySelector('[name="csrf-token"]').content;
console.log("Found CSRF Token:(",csrfToken,")");
axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

class MyVerticallyCenteredModal extends React.Component {
  handleSubmit(e) {
    //debugger
    event.preventDefault();
    let email = e.target.elements[0].value;
    let message = e.target.elements[1].value;
    //alert('calling axios.post from SendMeMail email:'+email+' message:'+message);
    // let data = JSON.stringify({
    //     email: email,
    //     message: message
    // })

     //{
     //   message: `${message}`,
     //   email: `${email}`
     // })

    axios.post('/api/contact_me_via_email', 
     {
        message: `${message}`,
        email: `${email}`
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

      this.props.onHide();
    }

    render() {
      return (
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Send Me an Email
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container" style={{padding:'40px 0'}}>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <h4>Look forward to hearing from you :)</h4>
                        <form onSubmit={this.handleSubmit.bind(this)}>
                            <input type="email" name="email" className="form-control emailForm" placeholder="Your email address"/>
                            <textarea type="text" name="message" className="form-control emailForm" placeholder="Your message"/>
                            <button className="btn btn-primary emailForm" type="submit">Send Me Email</button>
                        </form>
                    </div>
                </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
  
  class ContactByEmailPopUp extends React.Component {
    constructor(...args) {
      super(...args);
  
      this.state = { modalShow: false };
    }
  
    render() {
      let modalClose = () => this.setState({ modalShow: false });
//<img src={this.props.email_icon} alt={this.props.email_text} style={{width:35}}/>
      //console.log(this.props.email_icon);
      //console.log(this.props.email_text);
      if (this.props.email_icon) {
        return (
            <span style={{cursor: "pointer"}}>
              <span style={{margin:10}} onClick={() => this.setState({ modalShow: true })}>
                <img src={this.props.email_icon} alt={this.props.email_text} style={{width:35}}/>
              </span>
              <MyVerticallyCenteredModal
                show={this.state.modalShow}
                onHide={modalClose}                
              />
            </span>
        );
      } else {
        return (
          <span style={{cursor: "pointer"}}>
              <span style={{margin:10}} onClick={() => this.setState({ modalShow: true })}>
                {this.props.email_text}
              <MyVerticallyCenteredModal
                show={this.state.modalShow}
                onHide={modalClose}
              />
              </span>          
              </span>
        );
        }
    }
  }
  
  export default ContactByEmailPopUp;