import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import BootstrapReactTest from './BootstrapReactTest';
import MusicMaster from './music_master/MusicMaster';


class App extends Component {
    render() {
        return (
                <Switch>       
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/music" component={MusicMaster}/>
                    <Route exact path="/bootstrap_react_test" component={BootstrapReactTest}/>
                </Switch>
        );
    }
}
//                    <Route exact path="/contact" component={ContactByEmailPopUp}/>

export default App;
