import React from 'react';

const Artist = ({artist}) => {

    if (!artist) return (<div>Not Found</div>);

    const {images, name, followers, genres} = artist;
    return (
        <div>
            <h3>{name}</h3>            
            <p>{genres.join(', ')}</p>            
            <p>{followers.total ? followers.total.toLocaleString() : ''} followers</p>
            <img src={images[0] && images[0].url} 
            alt='artist-profile' 
            style={{width: 200, 
                height: 200, 
                borderRadius: 100, 
                objectFit: 'cover'}} />

        </div>
    );
}

export default Artist;